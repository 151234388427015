.panel-heading {
  color: #fff;
  background-color: #38a8c3;
  border-color: #38a8c3;
  padding: 7px;
}
.usersli-main {
  margin: 20px;
}
.usersli-main .usersli-table {
  box-shadow: 0 12px 24px 0 rgb(53 43 103 / 7%);
  border-radius: 20px;
}
.usersli-main .usersli-table h6 {
  text-align: left;
  color: #8dbbdb;
}
.usersli-main .usersli-table .MuiTableRow-footer .MuiTableCell-root {
  border-bottom: none !important;
}
.usersli-main .usersli-table > .MuiToolbar-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.contributor-modal {
  backdrop-filter: blur(3px);
}
.contributor-modal .MuiBackdrop-root {
  background-color: rgb(255 255 255 / 70%);
}
.contributor-modal .MuiDialog-paperScrollPaper {
  box-shadow: 0 32px 44px 0 rgb(53 43 103 / 7%);
}
.contributor-modal button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.contributor-modal label {
  float: left;
}
.f-w-500 {
  font-weight: 500;
}
