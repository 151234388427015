p.MuiTypography-root.head_tag.MuiTypography-body1 {
  color: #fff;
  line-height: 100%;
  margin: 0;
  font-size: 18px;
}
div#panel1a-header {
  background: rgb(4, 95, 172);
}
.MuiCardContent-root {
  padding: 15px;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  cursor: pointer;
  border-radius: 12px;
}
.list_show_no_record{
  margin-left: 0px;
  padding: 10px;
  margin-top: 180px !important;
  margin: 0px;
  /* display: flex; */
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;
}
.list_show {
  margin-left: 0px;
  padding: 10px;
  margin-top: 110px !important;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;
}
.list_show h5 {
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #8dbbdb;
}
input:focus .header-search-bar {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%) !important;
}
.header-search-bar {
  border: 1px solid rgba(24, 39, 47, 0.1);
  box-shadow: none !important;
  height: 37px;
  display: flex;
  width: 100%;
}
.search-input-div {
  width: 100%;
  margin-right: 20px;
}
.search-input-div input {
  font-size: 14px;
}
.search-input-div svg path {
  color: #75859a;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #75859a !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: rgb(117 133 154 / 10%) !important;
}
.search-input-div input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.search-input-div input {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.list_show h6 {
  text-align: left;
  font-family: "Poppins", sans-serif;
  margin-bottom: 2px;
  color: #112f56;
}
.list_show.f-end {
  flex-direction: row-reverse;
}
.search_div {
  background-color: #fff;
  border-bottom: 1px solid rgba(24, 39, 47, 0.1);
  box-shadow: none !important;
  margin: 0px;
  padding: 20px;
  position: fixed;
  top: 0;
  width: calc(100% - 240px);
  justify-content: space-between;
  border: 1px solid rgba(24, 39, 47, 0.1);
  box-shadow: none !important;
}
.search_div .header-top {
  display: flex;
  justify-content: space-between;
}
.search_div .header-top form {
  width: 100%;
  margin-right: 15px;
}
.checkbox-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.checkbox-group label.MuiFormControlLabel-root {
  margin: 0px;
  margin-right: 10px;
}
.checkbox-group label.MuiFormControlLabel-root span {
  padding: 0px;
  font-size: 14px;
  margin-right: 0px;
}
.checkbox-group .MuiFormControlLabel-root span.MuiTypography-root {
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
  color: #112f56;
}
.checkbox-group label.MuiFormControlLabel-root span svg {
  width: 15px;
  height: 15px;
}
label.MuiFormControlLabel-root {
  margin-top: 0%;
  display: flex;
}
span.MUIDataTableHeadCell-contentWrapper-157 {
  margin-left: 6%;
}
label.MuiFormControlLabel-root.approve_check {
  margin-bottom: 0px;
}
.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}
nav.MuiPagination-root.pagination_div {
  float: right;
}
.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
  float: left;
  border-radius: 15px 0 0 15px;
}
.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
  width: 31.46% !important;
  flex: 0 0 31.46% !important;
  margin: 10px !important;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  /* border: 1px solid rgba(24, 39, 47, 0.1); */
  justify-content: space-between;
  border-radius: 15px;
  min-height: 158px;
}
.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded .makeStyles-details-122{
  width: calc(100% - 120px);
  border: 1px solid rgba(24, 39, 47, 0.1);
  border-right: 0;
  border-radius: 15px 0 0 15px;
}  
.MuiAccordionDetails-root {
  display: block !important;
}
.MuiIconButton-label svg {
  margin: 0px !important;
}
.col-md-10.datepicker_tag {
  margin-top: 18px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.editior_btn.MuiButton-containedPrimary {
  margin-top: -5%;
  margin-left: 31%;
}
/* button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn.btn-info.edit_btn_translator.MuiButton-containedPrimary {
  float: right;
  margin-top: -36px;
} */
input.form-control.text-capitalize.date_published_error {
  /* border-color: red; */
}
.react-datepicker-popper[data-placement^=bottom] {
  transform: none !important;
  inset: auto !important;
}
.row>* {
  position: relative !important;
}

svg.MuiSvgIcon-root.close_icon {
  margin-top: 36px;
}
/* button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn_close_cancel.MuiButton-containedPrimary {
  margin-top: 1%;
} */
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.translator_btn.MuiButton-containedPrimary {
  margin-top: 0%;
  margin-left: 29%;
}
nav.MuiPagination-root.pagination_hidediv {
  display: none;
}
.btn-info:hover {
  color: #fff !important;
}
svg.MuiSvgIcon-root.book_icon {
  margin-top: 36px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn.btn-info.ml-2.MuiButton-containedPrimary {
  margin-top: -11%;
  margin-left: 68%;
  padding: 6px;
}
.makeStyles-sideBarHeight-10 {
  -moz-box-shadow: 2px 0 5px 0 rgb(57 63 72 / 30%);
  -webkit-box-shadow: 2px 0 5px 0 rgb(57 63 72 / 30%);
  box-shadow: 2px 0 5px 0 rgb(57 63 72 / 30%);
  z-index: 9999;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.book_btn.MuiButton-containedPrimary {
  float: left;
  margin-top: 10px !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn.btn-info.MuiButton-containedPrimary {
  margin-left: 15px;
  margin-top: 10px;
}
.pro-sidebar {
  float: left;
}
.ForwardRef-root-126 {
  width: 50% !important;
}
.header-search-bar {
  border: 1px solid rgba(24, 39, 47, 0.1);
  box-shadow: none !important;
  height: 37px;
  display: flex;
  width: 100%;
}
.head_tag1 {
  background-color: rgb(4, 95, 172);
  color: #fff;
  line-height: 100%;
  margin: 0px;
  font-size: 18px;
  padding: 11px 16px;
  min-height: 48px;
  justify-content: center;
}
.header-bg {
  background: #84abc6 !important;
  color: #ffffff;
}
.list_reference{
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}
.MuiSvgIcon-root.no-margin{
  margin-left: 0px !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.book_btn.all_btn_width.MuiButton-containedPrimary {
  width: 100px;
}
.search-user-tbl form{
  max-width: 300px;
  width:100%;
}
.user_search_div{
  /* padding: 0px;
  display: flex;
  height: 37px;
  position: relative;
  width: 100%; */
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.book_btn.all_btn_width.width_btn.MuiButton-containedPrimary {
  margin-top: 0 !important;
}
.modal-footer{
  padding: 8px 24px !important;
}
.modal-footer .delete_btn{
  background-color: #dc3545;
  color: #ffffff;
  margin-right: auto;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.primary {
  width: 100px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn_close_cancel.btn_close_width.MuiButton-containedPrimary {
  margin-top: 10px;
}
span.comman_count{
  margin-top: -2px;
  margin-left: -7px;
  font-size:14px;
  font-family: "Poppins", sans-serif;
}
