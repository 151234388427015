body {
  margin: 0;
  padding: 0;
  /* background-color: #17a2b8; */
  height: 100vh;
}
#login .container #login-row #login-column #login-box {
  margin-top: 120px;
  max-width: 600px;
  height: 320px;
  border: 1px solid #9c9c9c;
  background-color: #eaeaea;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}
.loader {
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color_reds {
  color: red;
}
span.login100-form-title.p-b-45 {
  font-family: Montserrat-ExtraBold;
  font-size: 1rem;
}
p.MuiFormHelperText-root {
  color: red;
}
#login
  .container
  #login-row
  #login-column
  #login-box
  #login-form
  #register-link {
  margin-top: -85px;
}
.errorMsg {
  color: #cc0000;

  margin-bottom: 12px;
}

/* ===========header======== */
nav.header_bg.navbar.navbar-expand-md.navbar-light {
  background: #38a8c3;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #38a8c3 !important;
}

a.logo.navbar-brand {
  font-size: 23px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-left: 42px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0;
}

span.pro-item-content {
  color: #fff;
  line-height: 2.5;
  display: block;
  padding: 8px 15px;
  font-size: 15px;
  font-weight: 600;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #fff !important;
}

.img_div {
  text-align: center;
}

/* .MuiSvgIcon-root {
fill: #fff !important;
margin-bottom: -35%;

} */
img.img_class {
  border-radius: 50px;
}
hr.line_class {
  color: #fff;
}
.login-form-left form {
  max-width: 500px;
  width: 500px;
}
.login-form-left .MuiContainer-fixed {
  padding: 0px !important;
}
.login-form-left .MuiContainer-fixed .MuiGrid-item {
  padding: 0px !important;
}
.login-form-logo-right {
  max-width: 350px;
  width: 350px;
  display: flex;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  border: 1px solid rgba(24, 39, 47, 0.1);
  border-radius: 0px 15px 15px 0px;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #84abc6 !important;
  flex-direction: column;
  height: 276.5px;
}
.login-form-logo-right img {
  max-height: 80px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.login-form-logo-right h1 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 800;
}
.login_form {
  /* margin-top: 11%;
margin-left: 35%; */
  justify-content: center;
  align-items: center;
  height: calc(100vh - 0px);
}
.panel-heading {
  color: #fff;
  background-color: #8dbbdb;
  border-color: #8dbbdb;
  padding: 7px;
  margin-bottom: 20px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}
.login-form-content {
  display: flex;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  border: 1px solid rgba(24, 39, 47, 0.1);
  border-radius: 15px 0px 0px 15px;
  padding: 0;
  max-width: 600px;
  background-color: #fff;
  flex-direction: column;
  height: 276.5px;
}

.MuiSvgIcon-root {
  margin-left: 18px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  margin-top: -21%;
  /* line-height: 2.5; */
  padding: 10px 61px;
}
.container {
  /* background-image: url('../../img/bg.png');
background-size: contain;
background-position: left center; */
}
.action-form {
  margin-bottom: 20px;
}
h1.logo.m-0 {
  font-size: 23px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-left: 42px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0;
}
.login-form-content .MuiInputLabel-formControl {
  left: 60px;
}
.login-form-content .MuiFormControl-marginNormal {
  padding: 0px 20px 0px 20px;
}
.MuiFormControl-marginNormal {
  margin-top: 16px;
  margin-bottom: 8px;
}
.login-form-content svg {
  margin: 0px 4px !important;
}
.primary {
  background-color: #84abc6 !important;
  color: #fff !important;
  margin: 0px 10px !important;
  border: 1px solid #84abc6 !important;
}
.primary:hover {
  background-color: #6689a2 !important;
  color: #fff !important;
  border: 1px solid #6689a2 !important;
}
.secondary {
  background-color: transparent !important;
  color: #84abc6 !important;
  margin: 0px 10px !important;
  border: 1px solid #84abc6 !important;
}
.secondary:hover {
  background-color: #6689a2 !important;
  color: #fff !important;
}
.action-form {
  margin-bottom: 20px;
  margin-top: 20px;
}
.makeStyles-root-1 {
  justify-content: center !important;
}
