.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.f-w-700 {
  font-weight: 700;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #8dbbdb !important;
}
.MUIDataTableBodyRow-root-79.mui-row-selected {
  background-color: hsl(205deg 37% 65% / 20%) !important;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: hsl(205deg 37% 65% / 20%) !important;
}
tr.mui-row-selected {
  background-color: hsl(205deg 37% 65% / 20%) !important;
}
label.MuiFormLabel-root.Mui-focused {
  color: #8dbbdb !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #8dbbdb !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #8dbbdb !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #84abc6 !important;
}
.MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: rgb(132 171 198 / 20%) !important;
}
.c-pointer {
  cursor: pointer !important;
}
.c-pointer:hover {
  cursor: pointer !important;
}

.fp-loader {
  border-radius: 50px;
  -webkit-animation: fadeInFromNone 3s ease-out infinite;
    -moz-animation: fadeInFromNone 3s ease-out infinite;
    -o-animation: fadeInFromNone 3s ease-out infinite;
    animation: fadeInFromNone 3s ease-out infinite;
}

/* / width / */
::-webkit-scrollbar {
  width: 0px;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* / Handle / */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@-webkit-keyframes fadeInFromNone {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes fadeInFromNone {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-o-keyframes fadeInFromNone {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeInFromNone {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}